import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponseData, Response } from '@model/interfaces/response.interface';
import {
  connectToCSVForm,
  SFTPConnectionResponse,
  SFTPCreateConnectionData,
  SFTPUpdateUserData
} from '@model/interfaces/sftp.interface';
import { AlertService } from '@services/alert/alert.service';
import { catchError, map, Observable } from 'rxjs';
import { CustomOperators } from 'src/app/shared/operators/custom-operators';

@Injectable({
  providedIn: 'root'
})
export class SftpSyncService {
  constructor(private alert: AlertService, private http: HttpClient, private operator: CustomOperators) {}

  /**
   * Gets the connection
   * @returns The SFTP connection data
   */
  getConnection(): Observable<SFTPConnectionResponse> {
    return this.http.post<ResponseData<SFTPConnectionResponse>>('/sftpSync/getConnection', null).pipe(
      map(value => {
        if (!value.success) {
          void this.alert.defaultErrorMessage(this.alert.translate('EitheremployeesMailtasticavailable'));
        }
        return value.data;
      }),
      catchError(() => {
        throw new Error(this.alert.translateDataNotLoaded());
      })
    );
  }

  /**
   * Gets connected providers
   * @returns Connected providers array
   */
  connectedProviders(): Observable<string[]> {
    return this.http.get<ResponseData<string[]>>('/crm/connectedProviders').pipe(this.operator.extractResponseData());
  }

  /**
   * Checks if the connection exists
   * @param data - The connection data
   * @returns Success of true
   */
  checkConnection(data: connectToCSVForm): Observable<true> {
    return this.http.post<Response>('/sftpSync', data).pipe(this.operator.extractResponse());
  }

  /**
   * Checks the CSV file
   * @param data - The connection data
   * @returns The sting of CSV file datas
   */
  checkCSV(data: connectToCSVForm): Observable<string> {
    return this.http.post<ResponseData<string>>('/sftpSync/checkCSV', data).pipe(this.operator.extractResponseData());
  }

  /**
   * Creates a connection
   * @param data - The SFTP create conneection data
   * @returns The success
   */
  createConnection(data: SFTPCreateConnectionData): Observable<true> {
    return this.http
      .post<ResponseData<true>>('/sftpSync/createConnection', data)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Removes the connection
   * @returns Success of true
   */
  removeConnection(): Observable<{ id: string }[]> {
    return this.http
      .post<ResponseData<{ id: string }[]>>('/sftpSync/removeConnection', null)
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Updates the connection
   * @param syncSettings
   * @returns
   */
  updateConnection(syncSettings: { connectionData: connectToCSVForm }): Observable<number[]> {
    return this.http
      .post<ResponseData<number[]>>('/sftpSync/updateConnection', { syncSettings: syncSettings })
      .pipe(this.operator.extractResponseData());
  }

  /**
   * Starts the sync
   * @returns The updated user details
   */
  startSync(): Observable<SFTPUpdateUserData> {
    return this.http
      .put<ResponseData<SFTPUpdateUserData>>('/sftpSync/sync/userdata/all', null)
      .pipe(this.operator.extractResponseData());
  }
}
